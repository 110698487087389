import { axiosInstance } from "./axiosInstance-hris";

declare module Leaves {
  type payperiodsQuantity = {
    id?: string;
    startDate: string;
    endDate: string;
    quantity: number;
    string?: string;
  };
  type fetchBusinessLeaves = {
    id: string;
    createdAt: string;
    empId: string;
    leaveCategory: string;
    payrollPayItemLeaveId: string;
    leaveEntitlementId: string;
    startDate: string;
    endDate: string;
    quantity: number;
    description: string;
    status: string;
    note: string;
    payoutDate: string;
    payPeriods?: Array<payperiodsQuantity>;
  };
  type changeLeaveStatus = {
    status: string;
    note: string;
  };
  type PostBulkPayPeriods = {
    empId: string;
    payPeriods: Array<payperiodsQuantity>;
  };
  type CreateLeavesBulk = {
    empIds: Array<string>;
    leaveRequest: {
      startDate?: string;
      endDate?: string;
      quantity: number;
      description: string;
      leaveCategory: string;
      payrollPayItemLeaveId: string;
      payoutDate?: string;
    };
    employeesPayPeriods?: Array<PostBulkPayPeriods>;
  };

  type fetchEntitlementsHours = {
    id: string;
    balance: number;
  };
  type BulkApprove = {
    leaveIds: Array<string>;
    status: string;
  };
}
module Leaves {
  const API_URL = "/employee-leaves";
  export function CreateLeavesBulk(
    data: CreateLeavesBulk,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/create-leaves-bulk`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
  export function fetchBusinessLeaves(
    params: {
      status: "APPROVED" | "REJECTED" | "PENDING";
      page?: number;
      limit?: number;
    },
    success: (response: any) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .get(`${API_URL}/team-leaves`, { params })
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  }
  export function ApproveAllLeaves(
    data: BulkApprove,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/change-leave-status-bulk`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }

  export function changeLeaveStatus(
    id: string,
    data: changeLeaveStatus,
    success: (message: string) => void,
    error: (message: string) => void
  ) {
    axiosInstance
      .post(`${API_URL}/change-leave-status/${id}`, data)
      .then(({ data }) => {
        success(data?.message);
      })
      .catch((e) => {
        error(e?.response?.data?.message ?? e?.message);
      });
  }
}
export { Leaves };
